
import {computed, defineComponent, onMounted, ref} from 'vue'
import {ClientSelect, LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import RecentCards from "@/components/base/common/RecentCards.vue";
import Entities from "@/components/base/common/Entities.vue";
import ClaimViewCard from "@/views/claim/ClaimViewCard.vue";

export default defineComponent({
  name: "SearchNewCases",
  components: {ClaimViewCard, Entities, RecentCards},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Fast Track Claims',
        [
          {link: false, router: '', text: 'Fast Track Claims'}
        ]
      )
    })

    const objectFilter = ref({clientId: '', billed: 500, reserve: 500})
    const page = computed(() => store.state.ClaimModule.fastTrack)
    return {
      page,
      objectFilter,
      ...ClientSelect(false),
      ...LoadFilterObjects(Actions.CLAIM_FAST_TRACK, objectFilter.value, [])
    }
  },
  methods: {
    onDiscard() {
      this.objectFilter.clientId = '';
      this.objectFilter.billed = 500
      this.objectFilter.reserve = 500
      this.paginationLoad()
    },
    onSearch() {
      this.updateFilterObject(this.objectFilter)
    }
  }

})
